<template>
    <div class="statistics-container">
        <v-row justify="center">
            <v-col cols="12" md="3">
                <div class="counter-container">
                    <div class="counter-container__label">Bestellungen</div>
                    <div class="counter-container__count">{{ orders.length }}</div>
                </div>
            </v-col>
            <v-col cols="12" md="3">
                <div class="counter-container">
                    <div class="counter-container__label">Umsatz</div>
                    <div class="counter-container__count">{{ grossMargin }} €</div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "OrdersStatistics",
    props: {
        orders: {
            type: Array,
            required: true
        }
    },
    computed: {
        grossMargin() {
            return this.orders.reduce((acc, order) => order?.payment ? acc +  order?.payment.totalAmount : acc, 0).toFixed(2)
        }
    }

}
</script>

<style lang="scss" scoped>
.statistics-container {
}

.counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__count {
        font-size: 48px;
        font-weight: 600;
        line-height: 68px;
    }

    &__label {
        font-weight: 400;
        font-size: 16px;
    }
}
</style>