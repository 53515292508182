import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OrderDetails from '@/components/OrderDetails'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/orderDetails/:id',
        name: 'orderDetails',
        component: OrderDetails,
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

export default router
