<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        Bestellungen
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Suchen"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table :search="search" :headers="headers" :items="orders" @click:row="(item) => viewOrderDetails(item.uid)">
                        <template v-slot:[`item.totalAmount`]="{ item }">
                            {{ item.payment?.totalAmount || 0 }} €
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import dayjs from "dayjs";
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default {
    name: 'OrdersList',
    data() {
        return {
            search: '',
            headers: [
                {
                    text: "Order ID",
                    value: "orderId",
                },
                {
                    text: "Creation Date",
                    value: "creationDate",
                },
                {
                    text: "Fullfiller ID",
                    value: "fulfiller_id",
                },
                {
                    text: "Stauts",
                    value: "status",
                },
                {
                    text: "Email",
                    value: "email",
                },
                {
                    text: "Summe",
                    value: "totalAmount",
                },
                // Add more columns here as needed
            ],
        }
    },
    props: {
        orders: {
            type: Array,
            required: true
        }
    },
    methods: {
        viewOrderDetails(id) {
            this.$router.push(`/orderDetails/${id}`)
        }
    },
}
</script>
