<template>
    <v-card>
        <v-card-title>Order Details</v-card-title>
        <v-card-text>
            <p><b>Order Dare</b> {{ creationDate }}</p>
            <p><b>Email:</b> {{ order.email }}</p>
            <p><b>Fulfiller:</b> {{ order.fulfiller }}</p>
            <p><b>Fulfiller ID:</b> {{ order.fulfiller_id }}</p>
            <p><b>Invoice Number:</b> {{ order.invoiceNumber }}</p>
            <p><b>Is Fulfilled:</b> {{ order.isFulfilled }}</p>
            <p><b>Is Paid:</b> {{ order.isPayed }}</p>
            <p><b>Line Items:</b></p>
            <v-data-table :headers="headers" :items="order.lineItems" hide-default-footer />
            <p><b>Order ID:</b> {{ order.orderId }}</p>
            <p><b>Status:</b> {{ order.status }}</p>
            <p><b>Stripe Payment Intent:</b> {{ order.stripePaymentIntent }}</p>
            <p><b>Stripe Session ID:</b> {{ order.stripeSessionId }}</p>
        </v-card-text>
    </v-card>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import {db} from "../../firebase";
import dayjs from "dayjs";

export default {
    data() {
        return {
            order: {},
            headers: [
                { text: "SKU", value: "sku" },
                { text: "Quantity", value: "quantity" },
                { text: "Template URL", value: "templateUrl" },
            ],
        };
    },
    computed: {
        creationDate() {
            return dayjs(this.order.creationDate).format('DD.MM.YYYY HH:mm')
        }
    },
    created() {
        this.loadOrder()
    },
    methods: {
        async loadOrder() {
            const orderId = this.$route.params.id;
            const docRef = doc(db, "orders", orderId);
            const docSnap = await getDoc(docRef);

            this.order = docSnap.data()
        }
    }
};
</script>
