<template>
    <OrderPage />
</template>

<script>
import OrderPage from "@/components/OrderPage";

export default {
    name: 'HomeView',

    components: {
        OrderPage,
    },
}
</script>
