<template>
    <div class="card-container">
        <v-img class="mb-12" :src="require('@/assets/logo.png')" width="100%"/>
        <v-form ref="entryForm" class="pl-3 pr-3" @submit.prevent="login">
            <v-text-field
                background-color="rgba(256,256,256,0.4)"
                v-model="email"
                outlined
                :disabled="isProcessingLogin"
                label="Deine Email"
            />
            <v-text-field
                background-color="rgba(256,256,256,0.4)"
                type="password"
                :disabled="isProcessingLogin"
                outlined
                v-model="password"
                label="Dein Passwort"
            />
            <v-btn
                :loading="isProcessingLogin"
                :disabled="isProcessingLogin || !password || !email"
                type="submit"
                x-large
                width="400px"
                color="success"
                >Login</v-btn
            >
        </v-form>
    </div>
</template>

<script>
import {
    getAuth,
    setPersistence,
    browserSessionPersistence,
    signInWithEmailAndPassword,
} from 'firebase/auth'

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            isProcessingLogin: false,
            loading: true,
            error: false,
            errorMessage: '',
        }
    },
    methods: {
        async login() {
            this.isProcessingLogin = true
            const auth = getAuth()
            setPersistence(auth, browserSessionPersistence)
                .then(async () => {
                    const signIn = await signInWithEmailAndPassword(
                        auth,
                        this.email,
                        this.password
                    )
                    if (signIn.user) {
                        this.$router.replace(this.$route.query.redirect)
                    }
                })
                .catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code
                    const errorMessage = error.message
                })
            this.isProcessingLogin = false
        },
    },
}
</script>

<style lang="scss" scoped>
.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 400px;
    max-width: 90vw;
}
.card-content {
    margin-top: 24px;
}
</style>
