<template>
    <v-container>
        <div class="order-filters">
           <DatePickerField :range="true" @change="(date) => $emit('dateChange', date)" />
            <v-switch
                class="ml-6"
                v-model="errorsOnly"
                label="Nur Errors zeigen"
            ></v-switch>
        </div>
    </v-container>
</template>

<script>
import DatePickerField from "@/components/DatePickerField";
export default {
    name: "OrderFilters",
    components: {DatePickerField},
    data() {
        return {
            errorsOnly: false
        }
    },
    watch: {
        errorsOnly() {
            this.$emit('errorsOnlyChange', this.errorsOnly)
        }
    }
}
</script>

<style lang="scss" scoped>
.order-filters {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
}
</style>