<template>
    <v-app>
        <v-app-bar v-if="!!user" app color="primary" dark>
            <div class="d-flex align-center">
                <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    :src="require('@/assets/logo.png')"
                    transition="scale-transition"
                    width="160"
                />
            </div>

            <v-spacer></v-spacer>

            <v-btn @click="logout" text> LOGOUT </v-btn>
        </v-app-bar>
        <v-main v-if="user">
            <router-view />
        </v-main>
        <div
            v-else
            class="d-flex align-center justify-center"
            style="height: 100vh"
        >
            <Login />
        </div>
    </v-app>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth'
import { app, functions } from '../firebase'
import Login from '@/components/Login'
import { httpsCallable } from 'firebase/functions'

export default {
    name: 'App',
    components: { Login },
    data() {
        return {
            user: null,
        }
    },
    mounted() {
        const auth = getAuth(app)
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.user = user
            } else {
                this.user = null
            }
        })
    },
    methods: {
        async setClaims() {
            const setCustomClaims = httpsCallable(
                functions,
                'setCustomClaimsForUserUid'
            )
            const uid = '8KU9RJvmt6Y8nXcYelgME6KrbMI3' // Assuming `this.user` contains the logged-in user object
            const data = {
                uid,
                customClaims: {
                    ADMIN: true,
                },
            }
            try {
                const result = await setCustomClaims(data)
                console.log('Custom claims set successfully:', result)
            } catch (error) {
                console.error('Error setting custom claims:', error)
            }
        },
        async getClaims() {
            const getCustomClaims = httpsCallable(functions, 'getCustomClaims')
            const res = await getCustomClaims()
            console.log(res)
        },
        logout() {
            const auth = getAuth()
            signOut(auth)
        },
    },
}
</script>
