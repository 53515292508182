<template>
    <div class="state-display">
        <v-progress-circular indeterminate />
    </div>
</template>

<script>
export default {
    name: 'StateDisplay',
}
</script>

<style lang="scss" scoped>
.state-display {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    max-height: 100%;
    width: 100%;
}
</style>
