<template>
    <div style="width: 230px">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    outlined
                    :value="dateString"
                    label="Picker in menu"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                no-title
                scrollable
                :range="this.range"
                :max="maxDate"
            >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="applyDate"
                >
                    OK
                </v-btn>
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>
import dayjs from "dayjs";

const today = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
export default {
    name: "DatePickerField",
    data() {
        return {
            date: null,
            menu: false,
        }
    },
    props: {
        defaultDate: {
            default: today
        },
        defaultDates: {
            default: () => [today, today]
        },
        range: {
            default: false
        },
        maxDate: {
            default: dayjs().format('YYYY-MM-DD')
        }
    },
    computed: {
        dateString() {
            return this.range ? `${this.date[0]} - ${this.date[1]}` : this.date
        },
    },
    created() {
        this.date = this.range ? this.defaultDates : this.defaultDate
    },
    methods: {
        applyDate() {
            this.$emit('change', this.date)
            this.$refs.menu.save(this.date)
        }
    }
}
</script>

<style scoped>

</style>