<template>
    <div>
        <OrderFilters
            @errorsOnlyChange="(val) => (showErrorsOnly = val)"
            @dateChange="onDateChange"
        />
        <div v-if="!loading">
            <OrdersStatistics :orders="orders" />
            <OrdersList :orders="orders" />
        </div>
        <StateDisplay v-else />
    </div>
</template>

<script>
import OrdersList from '@/components/OrdersList'
import OrdersStatistics from '@/components/OrdersStatistics'
import {
    collection,
    limit,
    onSnapshot,
    orderBy,
    query,
    where,
} from 'firebase/firestore'
import { db } from '../../firebase'
import dayjs from 'dayjs'
import StateDisplay from '@/components/StateDisplay'
import OrderFilters from '@/components/OrderFilters'
import {orderStatus} from "@/constants";

export default {
    name: 'OrderPage',
    components: { OrderFilters, StateDisplay, OrdersStatistics, OrdersList },
    data() {
        return {
            orders: [],
            loading: false,
            date: [],
            dateFrom: dayjs(Date.now()).startOf('day').valueOf(),
            dateTo: dayjs(Date.now()).endOf('day').valueOf(),
            showErrorsOnly: false,
        }
    },
    created() {
        this.loadOrders()
    },
    computed: {
        filterTrigger() {
            return this.dateFrom + this.dateTo + this.showErrorsOnly
        },
    },
    watch: {
        filterTrigger() {
            this.loadOrders()
        },
    },
    methods: {
        async loadOrders() {
            this.orders = []
            this.loading = true
            const ordersCollection = collection(db, 'orders')

            const q = query(
                ordersCollection,
                where('isPayed', '==', true),
                where('creationDate', '>=', this.dateFrom),
                where('creationDate', '<=', this.dateTo),
                where('status', 'in', this.showErrorsOnly ? ['ERROR'] : Object.keys(orderStatus)),
                orderBy('creationDate'),
                limit(100)
            )

            // Listen for changes to the orders collection
            onSnapshot(q, (snapshot) => {
                this.loading = false
                snapshot.docChanges().forEach((change) => {
                    const doc = change.doc
                    const data = doc.data()
                    const order = {
                        ...data,
                        creationDate: dayjs(data.creationDate).fromNow(),
                        uid: doc.id,
                    }

                    // Handle the different types of document changes
                    if (change.type === 'added') {
                        // Add the new order to the beginning of the array
                        this.orders.unshift(order)
                    } else if (change.type === 'modified') {
                        // Find the existing order and replace it with the updated order
                        const index = this.orders.findIndex(
                            (o) => o.uid === doc.id
                        )
                        this.orders.splice(index, 1, order)
                    } else if (change.type === 'removed') {
                        // Remove the deleted order from the array
                        const index = this.orders.findIndex(
                            (o) => o.uid === doc.id
                        )
                        this.orders.splice(index, 1)
                    }
                })
            })
        },
        onDateChange(rangeArray) {
            this.dateFrom = dayjs(rangeArray[0]).startOf('day').valueOf()
            this.dateTo = dayjs(rangeArray[1]).endOf('day').valueOf()
        },
    },
}
</script>

<style scoped></style>
